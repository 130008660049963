/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import React, { useEffect, useState, useRef } from 'react';
import Link from 'next/link';
import {useRouter} from 'next/router';

// import { Link, useParams, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import PropTypes, { object } from 'prop-types'
import { bindActionCreators } from 'redux'
import ActionCreators from '../../store/ActionCreators'
import { apiResponseStatuses } from '../../config/constants/ApiServiceConstants';
import { CustomTextInput, CustomButton, CustomHeader, CustomLoader } from '../../components/commons'
import { isValidRequiredField, isValidEmail, renderRequiredError, renderInvalidEmailError } from '../../helpers/form'
import { preAuthPaths } from '../../config/constants/NavigationConstants'

const mapStateToProps = (state) => ({
    auth: state.authReducer,
    signUpReducer: state.signUpReducer,
    treesReducer: state.treesReducer,
})

const mapDispatchTopProps = (dispatch) => bindActionCreators(ActionCreators, dispatch)

const connector = connect(mapStateToProps, mapDispatchTopProps)

const Login = ({...props}) => {

    const {auth, signUpReducer, treesReducer, verifyAccount, updateCredentials, storeMeetingData, resetAuthState} = props;
    const { responseStatus: verifiedResponseStatus, message: verifiedMessage = null, isLoading: isVerifiedLogin } = signUpReducer;
    const { isLoading, responseStatus, message, credentials } = auth;
    const {
        urlEmailId
	} = treesReducer;
    
    const { 
        token = null, 
        emailInVerificationUrl
    } = props;

    const [email, setEmail] = useState(urlEmailId || '');
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [password, setPassword] = useState('');
    const [showLoader, updateShowLoader] = useState(true);

	const urlSelectedTreeCount_F = props.count;
	const urlApiSource_F = props.transaction_system;
    const urlTransactionId_F = props.transaction_id;
    const passwordRef = useRef();
    const history = useRouter();

    const emailInputRef = useRef();

    useEffect(() => {
        if (token) {
            // first verify the user
            verifyAccount(token)
            .then((res)=> updateShowLoader(false));

            // then store meeting data         
            if(urlApiSource_F && urlSelectedTreeCount_F && urlTransactionId_F){
                storeMeetingData({
                    urlApiSource: urlApiSource_F, 
                    urlSelectedTreeCount: urlSelectedTreeCount_F, 
                    urlTransactionId: urlTransactionId_F
                });
            }
            // if the urlApiSource is panacea then we need to follow different
            // path
            else if(urlApiSource_F === 'restaurant'){
                storeMeetingData({
                    urlApiSource: urlApiSource_F, 
                    urlSelectedTreeCount: 1, 
                    urlTransactionId: -1
                }).then(()=>{
                    // directly navigate to login
                    // history.push(preAuthPaths.LOGIN);
                })			
            }
        } else {
            // token not available
            // check for email in the query params
            if(emailInVerificationUrl) {
                setEmail(emailInVerificationUrl);
                emailInputRef.current.value = emailInVerificationUrl;
            }
            updateShowLoader(false);
        }
    }, [token])

    useEffect(()=>{
        // just enter here only for first time
        // to check if there are api responses saved from last time
        // and clear redux cache
        if(!token && message && typeof credentials.token === 'undefined'){
            console.log('Clearing loggedIn cache');
            resetAuthState();
        }
        return ()=>{
            // stop loader
            updateShowLoader(false);
        }
    },[]);

    const doLogin = async () => {
        updateCredentials({ email, password, urlEmailId })
        setPassword('');
    }

    const doValidate = async () => {
        setIsSubmitted(true);
        if (isValidRequiredField(email, isSubmitted) && isValidRequiredField(password, isSubmitted) && isValidEmail(email)) {
            setIsSubmitted(false);
            await doLogin();
        }
    }

    if (isLoading || isVerifiedLogin) {
        return(<span>Loading...</span>)
    }

    const renderInvalidCredentialsError = () => {
        if (responseStatus === apiResponseStatuses.ERROR && message && token === null) {
            return (
                <span className='alert-danger'>{message}</span>
            );
        }

        return '';
    }

    const renderAccountVerifiedSuccess = () => {
        if (verifiedResponseStatus === apiResponseStatuses.SUCCESS && verifiedMessage && token !== null) {
            if(emailInVerificationUrl) {
                history.push(`${preAuthPaths.LOGIN}?email=${emailInVerificationUrl}`);
            }else {
                history.push(`${preAuthPaths.LOGIN}`);
            }
        }

        return '';
    }

    const renderAccountVerifiedError = () => {
        if (verifiedResponseStatus === apiResponseStatuses.ERROR && verifiedMessage && token) {
            return (
                <span className='alert-danger'>{verifiedMessage}</span>
            );
        }

        return '';
    }

    const changePasswordFieldType = (e) => {
        if (passwordRef.current.type === 'password') {
            passwordRef.current.type = 'text';
            e.target.setAttribute('src', './images/visibility.png')
        } else {
            passwordRef.current.type = 'password';
            e.target.setAttribute('src', './images/invisible.png')
        }
    }
    
    return (
        <section className="banner-sec">
            {/* <CustomHeader/> */}
            <CustomLoader 
                showLoader = { showLoader } 
            />
            <div className="container-wrap">
                <div className="inner-wrap">
                    <div className="cmn-head">
                        <h2>Log in to GiftTrees</h2>
                        <p>Log in to access your portal, view your trees and build your forest.</p>
                        {renderInvalidCredentialsError()}
                        {renderAccountVerifiedError()}
                        {renderAccountVerifiedSuccess()}
                    </div>
                    <form className="custom-form">
                        <div>
                            <CustomTextInput
                                onChange={ (e) => setEmail(e.target.value.toLowerCase()) }
                                placeholder="EMAIL ADDRESS"
                                defaultValue= { email }
                                inputRef= {emailInputRef}
                                inputStyle={{textTransform: 'lowercase'}}
                                id={'emailField'}
                            />
                            {renderRequiredError(email, isSubmitted, 'The email is required.')}
                            {renderInvalidEmailError(email, isSubmitted)}
                        </div>
                        {/* <CustomTextInput
                            type="password"
                            onChange={ (e) => setPassword(e.target.value) }
                            placeholder="PASSWORD"
                        /> */}
                           <div className="signup-password-field">                         
                            <div className="form-group" style={ {display: 'flex', flexDirection: 'column', alignItems: 'center'} }>
                                <input
                                    type="password"
                                    ref={ passwordRef }
                                    placeholder="PASSWORD"
                                    onChange={ (e) => setPassword(e.target.value) }
                                />
                            </div>
                            {password.length > 0  
                                ? <button type="button"><img alt="Password Visible" src='../images/invisible.png' onClick={ (e) => changePasswordFieldType(e) } /></button>
                                : null}
                        </div>
                        {renderRequiredError(password, isSubmitted, 'The password is required.')}
                        <div className="btn-wrp">
                            <CustomButton name="Log in" onClick={ () => doValidate() } />
                            <span className="login-link">
                                Forgotten your password? 
                                <Link href={preAuthPaths.FORGOTPASSWORD}>
                                    <strong className='forgot-password-link pointer'> Reset it now.</strong>
                                </Link>
                            </span>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    )
}

Login.defaultProps = {
    auth: {},
    signUpReducer: {},
    treesReducer: {},
    verifyAccount: null,
    updateCredentials: null,
    storeMeetingData: null,
    location: {}
}

Login.propTypes = {
    auth: PropTypes.instanceOf(Object),
    signUpReducer: PropTypes.instanceOf(Object),
    treesReducer: PropTypes.instanceOf(Object),
    verifyAccount: PropTypes.func,
    updateCredentials:  PropTypes.func,
    storeMeetingData: PropTypes.func,
    location: PropTypes.instanceOf(Object)
}
export default connector(Login);
